import React from "react"
import Figure from "../Figure"
import { CoverArt } from "./AudioPlayer.styles"

const LiveStreamArtwork = ({ image, video }) => {
  return (
    <div>
      {video ? (
        <CoverArt>
          <video
            style={{
              width: "100%",
              height: "100%",
            }}
            src={video.asset.url}
            autoPlay
            muted
            loop
          />
        </CoverArt>
      ) : image ? (
        <CoverArt>
          <Figure image={image} />
        </CoverArt>
      ) : null}
    </div>
  )
}

export default LiveStreamArtwork
