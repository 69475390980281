import React from "react"
import { Global, css } from "@emotion/core"
import { fonts } from "./vars"
import { mq } from "./utils"

import Bold from "../fonts/IBMPlexMono-Bold.woff2"
import Regular from "../fonts/IBMPlexMono-Regular.woff2"

const styles = css`
  @font-face {
    font-family: "IBM PLEX MONO REGULAR";
    src: url(${Regular}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "IBM PLEX MONO BOLD";
    src: url(${Bold}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
  }

  * {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.01);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-family: ${fonts.regular};
    font-size: 14px;
    line-height: 1.5em;
    margin: 0;

    ${mq.tablet} {
      font-size: 14px;
    }
    ${mq.laptop} {
      font-size: 16px;
    }
    ${mq.bigscreen} {
      font-size: 18px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  p {
    margin: 0 0 1em;
  }

  img {
    height: auto;
    display: block;
    width: 100%;
  }

  .link {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-size: 1em;
    font-family: ${fonts.bold};
  }

  strong {
    font-family: ${fonts.bold};
  }

  header,
  video,
  article,
  section,
  main,
  nav,
  footer {
    display: block;
  }

  .link {
    display: block;
    position: relative;
  }

  #modal {
    position: relative;
    z-index: 100;
  }
  svg {
    display: block;
  }
`
export default () => <Global styles={styles} />
