import React from "react"

const ThemeContext = React.createContext()

export const ThemeProvider = ({ children, theme }) => {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

function useTheme() {
  const ctx = React.useContext(ThemeContext)
  return ctx
}

export default useTheme
