import { css } from "@emotion/core"
import { mq } from "./utils"

export const wrapper = css`
  max-width: 1920px;
  margin: 0 auto;
  padding: 30px;

  ${mq.tablet} {
    padding: 60px;
  }
  ${mq.desktop} {
    max-width: 920px;
  }
  ${mq.bigscreen} {
    max-width: 1680px;
  }
`
