import styled from "@emotion/styled"
import { wrapper } from "../../styles/shared"
import { mq } from "../../styles/utils"

export const SiteWrapper = styled.div`
  min-height: 110vh;
  background: ${({ bgColor }) => bgColor || "none"};
  color: ${({ textColor }) => textColor || "#000"};
  a {
    color: ${props => props.textColor || "#000"};
  }
`

export const LayoutWrapper = styled.div`
  ${wrapper}
  display: flex;
  flex-wrap: wrap;
`

export const SiteMain = styled.main`
  position: relative;
  z-index: 2;
  padding-bottom: 100px;
`

export const ContentWrapper = styled.div`
  flex: 0 0 100%;
`
