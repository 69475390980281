import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutDisplay from "./LayoutDisplay"
import GlobalStyles from "../../styles/GlobalStyles"
import { ThemeProvider } from "../../hooks/useTheme"

const LayoutContainer = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      info: sanityInfo {
        bgColor {
          hex
        }
        textColor {
          hex
        }
        bg: siteBackground {
          ...SanityImage
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { bgColor, textColor, bg } = data.info
  const theme = { bgColor, textColor }
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <LayoutDisplay
          location={location}
          title={data.site.siteMetadata.title}
          bg={bg}
        >
          {children}
        </LayoutDisplay>
      </ThemeProvider>
    </>
  )
}

export default LayoutContainer
