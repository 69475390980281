import styled from "@emotion/styled"
import { mq } from "../../styles/utils"
import { animated } from "react-spring"

export const LiveStreamWrapper = styled.div`
  position: relative;
  font-size: 0.35em;
  line-height: 1.5em;
  text-transform: none;
  word-break: normal;
  .recordat-twitch {
    width: 100%;
    height: 100%;
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  h1 {
    font-size: 2em;
    line-height: 1em;
    margin-bottom: 1em;
  }
  ${mq.desktop} {
    font-size: 0.5em;
    line-height: 1.5em;
  }
  ${mq.bigscreen} {
    font-size: 0.25em;
    line-height: 1.5em;
  }
`

export const Slide = styled(animated.div)`
  position: absolute;
  width: 100%;
  left: 0;
`

export const StreamText = styled.div`
  transition: opacity 2s ease-in-out;
`
