import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { wrapper } from "../../styles/shared"
import { mq } from "../../styles/utils"

const fixed_styles = css`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  font-size: 2em;
`
const default_styles = css`
  position: relative;
  font-size: 4em;
  text-transform: uppercase;
  word-break: break-all;
  flex: 0 0 100%;
  ${mq.bigscreen} {
    font-size: 7.2em;
  }
`

export const WebsiteMain = styled.section`
  ${props => (props.isFront ? default_styles : fixed_styles)}
  line-height: 1.3em;
`

export const WebsiteMainInner = styled.div`
  ${props => !props.isFront && wrapper}
`

export const Message = styled.div`
  p {
    margin-bottom: 0;
  }
`
