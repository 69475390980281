import React from "react"
import PropTypes from "prop-types"
import { SiteHeader, Logo, NavLink } from "./Header.styles"

const Header = ({ textColor }) => (
  <SiteHeader>
    <Logo textcolor={textColor} to="/">
      <div>{`/ˈrɛkɔːdat/`}</div>
      <h1 aria-hidden>Recordat</h1>
    </Logo>
    <nav>
      <NavLink textcolor={textColor} to="/philosophy">
        {`//`}
      </NavLink>
    </nav>
  </SiteHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
