import styled from "@emotion/styled"

export const ImageBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
