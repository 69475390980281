import React from "react"
import AudioPlayerDisplay from "./AudioPlayerDisplay"

import { useReducer } from "react"

const reducer = (state, action) => {
  switch (action.type) {
    case "PLAY":
      return {
        ...state,
        playing: true,
      }

    case "PAUSE":
      return {
        ...state,
        playing: false,
      }

    default:
      return state
  }
}

const initialState = {
  playing: false,
  mainImage: null,
}

const Radio = ({ PlayerColor, stream }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { description, streamLink } = stream

  function setIsPlaying() {
    dispatch({
      type: "PLAY",
      playing: true,
    })
  }

  function setIsPaused() {
    dispatch({
      type: "PAUSE",
      playing: true,
    })
  }

  return (
    <AudioPlayerDisplay
      playerType="radio"
      setIsPlaying={setIsPlaying}
      setIsPaused={setIsPaused}
      PlayerColor={PlayerColor}
      playing={state.playing}
      title={description}
      streamURL={streamLink}
      playerType="radio"
    />
  )
}

export default Radio
