import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AudioPlayerDisplay from "./AudioPlayerDisplay"
import { useReducer } from "react"
import { useCallback } from "react"

const client_id = "98e22d69458acd40b88f39607f0b5ed8"

const reducer = (state, action) => {
  switch (action.type) {
    case "PLAY":
      return {
        ...state,
        playing: true,
      }

    case "PAUSE":
      return {
        ...state,
        playing: false,
      }

    case "LOADED_DATA":
      return {
        ...state,
        streamURL: action.payload.streamURL,
        title: action.payload.title,
        playlist: action.payload.playlist,
        mainImage: action.payload.mainImage,
      }

    default:
      return state
  }
}

const initialState = {
  streamURL: null,
  title: "",
  playing: false,
  playlist: [],
  mainImage: null,
}

const AudioPlayerContainer = ({ PlayerColor }) => {
  const data = useStaticQuery(graphql`
    query Mixes {
      mixes: allSanityMix {
        all: nodes {
          title
          soundcloud {
            duration
            streamURL
          }
          mainImage {
            ...SanityImage
          }
        }
      }
    }
  `)

  const [state, dispatch] = useReducer(reducer, initialState)
  const allMixes = useRef()

  function getRandomNumber(max) {
    return Math.floor(Math.random() * (Math.floor(max - 1) + 1))
  }

  const playRandomMix = useCallback(playlist => {
    const random = getRandomNumber(playlist.length)
    const randomTrack = playlist[random]
    const newPlaylist = playlist.filter(mix => mix.title !== randomTrack.title)

    dispatch({
      type: "LOADED_DATA",
      payload: {
        streamURL: randomTrack.soundcloud.streamURL,
        title: randomTrack.title,
        playlist: newPlaylist,
        mainImage: randomTrack.mainImage,
      },
    })
  }, [])

  useEffect(() => {
    allMixes.current = data.mixes.all
    playRandomMix(data.mixes.all)
  }, [data, playRandomMix])

  function playNextMix() {
    const playlist =
      state.playlist.length > 0 ? state.playlist : allMixes.current
    playRandomMix(playlist)
  }

  function setIsPlaying() {
    dispatch({
      type: "PLAY",
      playing: true,
    })
  }

  function setIsPaused() {
    dispatch({
      type: "PAUSE",
      playing: true,
    })
  }

  return (
    <AudioPlayerDisplay
      playerType="default"
      mainImage={state.mainImage}
      playNextMix={playNextMix}
      setIsPlaying={setIsPlaying}
      setIsPaused={setIsPaused}
      PlayerColor={PlayerColor}
      playing={state.playing}
      title={state.title}
      streamURL={`${state.streamURL}?client_id=${client_id}`}
    />
  )
}

export default AudioPlayerContainer
