import React from "react"
import { WebsiteMain, WebsiteMainInner } from "./Main.styles"
import { AudioPlayer } from "../AudioPlayer"
import LiveStream from "../AudioPlayer/LiveStream"
import Radio from "../AudioPlayer/Radio"

const Stream = ({ streamType, PlayerColor, live, radio }) => {
  switch (streamType) {
    case "radio":
      return <Radio PlayerColor={PlayerColor} stream={radio} />
    case "live":
      return <LiveStream PlayerColor={PlayerColor} stream={live} />
    default:
      return <AudioPlayer PlayerColor={PlayerColor} />
  }
}

const MainDisplay = ({ PlayerColor, isFront, streamType, live, radio }) => {
  return (
    <WebsiteMain isFront={isFront}>
      <WebsiteMainInner isFront={isFront}>
        <Stream
          streamType={streamType}
          PlayerColor={PlayerColor}
          live={live}
          radio={radio}
        />
      </WebsiteMainInner>
    </WebsiteMain>
  )
}

export default MainDisplay
