import React from "react"
import { useRef } from "react"
import Figure from "../Figure"
import PortableText from "../PortableText"
import {
  Player,
  PlayerContent,
  PauseBtn,
  PlayBtn,
  DefaultAudio,
  CoverArt,
} from "./AudioPlayer.styles"

const AudioPlayerDisplay = ({
  playNextMix,
  setIsPlaying,
  setIsPaused,

  title,
  playing,
  streamURL,
  PlayerColor,
  mainImage,
  playerType,
}) => {
  const color = PlayerColor.hex || "inherit"
  const player = useRef()

  function play() {
    player.current.play()
  }

  function pause() {
    player.current.pause()
  }

  return (
    <Player color={color}>
      {mainImage && (
        <CoverArt>
          <Figure image={mainImage} />
        </CoverArt>
      )}
      <DefaultAudio
        ref={player}
        onPause={setIsPaused}
        onPlay={setIsPlaying}
        onPlaying={setIsPlaying}
        onEnded={playNextMix}
        PlayerColor={PlayerColor}
        aria-hidden
        autoPlay
        controls
        src={`${streamURL}`}
      />
      {playerType === "radio" ? (
        <div className="radio-message">
          <PortableText blocks={title} />
        </div>
      ) : (
        title && <PlayerContent>{title}</PlayerContent>
      )}

      {playing ? (
        <PauseBtn color={color} onClick={pause}>
          {`//Pause`}
        </PauseBtn>
      ) : (
        <PlayBtn color={color} onClick={play}>
          {`//Listen`}
        </PlayBtn>
      )}
      {playerType === "default" && (
        <PauseBtn color={color} onClick={playNextMix}>
          {`>>Next`}
        </PauseBtn>
      )}
    </Player>
  )
}

export default AudioPlayerDisplay
