import styled from "@emotion/styled"
import { mq } from "../styles/utils"
import { Link } from "gatsby"
import { fonts } from "../styles/vars"

export const SiteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  position: relative;
  z-index: 2;
  h1 {
    visibility: hidden;
    font-size: 0;
  }
  ${mq.bigscreen} {
    margin-bottom: 62px;
  }
`

export const Logo = styled(Link)`
  text-decoration: none;
`

export const NavLink = styled(Link)`
  font-family: ${fonts.bold};
  text-decoration: none;
`
