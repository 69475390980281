import React from "react"
import { IconContext } from "react-icons/lib"

import Header from "../Header"
import SiteBackground from "../SiteBackground"
import { Main } from "../Main"
import useTheme from "../../hooks/useTheme"

import {
  SiteWrapper,
  LayoutWrapper,
  SiteMain,
  ContentWrapper,
} from "./Layout.styles"

const LayoutDisplay = ({ location, bg, children, title }) => {
  const { bgColor, textColor } = useTheme()
  const isFront = location.pathname === "/"
  return (
    <>
      <SiteWrapper bgColor={bgColor.hex} textColor={textColor.hex}>
        <LayoutWrapper>
          <IconContext.Provider value={{ color: textColor.hex || "#000" }}>
            <ContentWrapper>
              <Header textColor={textColor.hex} siteTitle={title} />
              {!isFront && <SiteMain>{children}</SiteMain>}
            </ContentWrapper>
            {bg && <SiteBackground bg={bg} />}
            <Main isFront={isFront} />
          </IconContext.Provider>
        </LayoutWrapper>
      </SiteWrapper>
    </>
  )
}

export default LayoutDisplay
