import React, { useEffect, useCallback, useState } from "react"

import { useReducer } from "react"
import PortableText from "../PortableText"
import { LiveStreamWrapper, Slide, StreamText } from "./LiveStream.styles"
import { PlayBtn } from "./AudioPlayer.styles"
import { useTransition } from "react-spring"
import StreamEmbed from "./StreamEmbed"
import LiveStreamArtwork from "./LiveStreamArtwork"

const reducer = (state, action) => {
  switch (action.type) {
    case "PLAY":
      return {
        ...state,
        playing: true,
      }

    case "PAUSE":
      return {
        ...state,
        playing: false,
      }
    case "CLEANUP":
      return {
        ...state,
        fullPerformanceStarted: true,
      }

    case "ENDED":
      return {
        ...state,
        performanceEnded: true,
      }
    default:
      return state
  }
}

const initialState = {
  playing: false,
  mainImage: null,
  fullPerformanceStarted: false,
  performanceEnded: false,
}

const LiveStream = ({ stream }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { intro, outro, isLive, video, image } = stream
  const [activeSlideIndex, updateActiveSlideIndex] = useState(0)

  const checkClock = useCallback(() => {
    const currentTime = new Date().getTime()
    const outroTime = new Date(stream.outroTimestamp).getTime()

    if (currentTime === outroTime || currentTime > outroTime) {
      dispatch({
        type: "ENDED",
      })
    }
  }, [])

  useEffect(() => {
    checkClock()

    if (!state.performanceEnded) {
      setInterval(checkClock, 1000 * 60)
    }
    return function cleanup() {
      clearInterval(checkClock)
    }
  }, [])

  function startFullPerformance() {
    dispatch({
      type: "CLEANUP",
    })
  }

  function showNextSlide() {
    updateActiveSlideIndex(activeSlideIndex + 1)
  }

  const hideText = useCallback(() => {
    dispatch({
      type: "CLEANUP",
    })
  }, [])

  useEffect(() => {
    const lastSlide = activeSlideIndex === intro.length - 1
    if (lastSlide) {
      setTimeout(hideText, 5000)
    }
  }, [activeSlideIndex])

  const transitions = useTransition(activeSlideIndex, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const lastSlide = activeSlideIndex === intro.length - 1

  return (
    <LiveStreamWrapper
      className={`${state.fullPerformanceStarted ? "fullscreen" : ""}`}
    >
      {!state.fullPerformanceStarted && (
        <LiveStreamArtwork image={image} video={video} />
      )}
      {!state.fullPerformanceStarted && (
        <StreamText className="content-slider">
          {transitions.map(({ item, props, key }) => {
            const content = intro[item]
            return (
              <Slide key={key} style={props}>
                <PortableText blocks={content.content} />
                {!lastSlide && isLive && (
                  <PlayBtn onClick={showNextSlide}>{`>>`}</PlayBtn>
                )}
                {lastSlide && (
                  <PlayBtn onClick={startFullPerformance}>{`//Listen`}</PlayBtn>
                )}
              </Slide>
            )
          })}
        </StreamText>
      )}
      {state.fullPerformanceStarted && <StreamEmbed />}
    </LiveStreamWrapper>
  )
}

export default LiveStream
