import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MainDisplay from "./MainDisplay"

const MainContainer = ({ isFront }) => {
  const { info } = useStaticQuery(graphql`
    query Status {
      info: sanityInfo {
        live: _rawLiveStream(resolveReferences: { maxDepth: 10 })
        radio: _rawLiveRadio(resolveReferences: { maxDepth: 10 })
        streamType
        PlayerColor {
          hex
        }
      }
    }
  `)

  return (
    <MainDisplay
      isFront={isFront}
      PlayerColor={info.PlayerColor}
      live={info.live}
      radio={info.radio}
      streamType={info.streamType}
    />
  )
}

export default MainContainer
