import React from "react"
import { ImageBG } from "./SiteBackground.styles"
import Figure from "./Figure"

const SiteBackground = ({ bg }) => {
  if (!bg.asset) return null
  return (
    <ImageBG>
      <Figure image={bg} />
    </ImageBG>
  )
}

export default SiteBackground
