import styled from "@emotion/styled"
import { css, keyframes } from "@emotion/core"
import { fonts } from "../../styles/vars"

export const Player = styled.div`
  color: ${props => props.color};
  .radio-message {
    font-family: ${fonts.bold};
    display: inline;
    margin-right: 0.5em;
    > p {
      display: inline;
      margin: 0;
      font-size: 1em;
    }
  }
`

export const PlayerContent = styled.h1`
  display: inline;
  position: relative;
  margin-right: 0.25em;
`

const default_btn_styles = css`
  margin-right: 0.5em;
  position: relative;
  cursor: pointer;
  font-family: ${fonts.bold};
  display: inline-block;
`

const blink = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
  }
`

export const PlayBtn = styled.span`
  ${default_btn_styles}
  animation: ${blink} 2s step-end infinite;
  transform-origin: center;
`
export const PauseBtn = styled.span`
  ${default_btn_styles}
  color: ${props => props.color};
`

export const DefaultAudio = styled.audio`
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  transform: translateX(-100vw);
`

export const CoverArt = styled.div`
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
